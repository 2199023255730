<template>
  <div>
    <b-tabs>
    <b-tab title="FDIH & PIAS">
      <MFdihpias />
    </b-tab>
    <b-tab title="KU" lazy>
      <MKu/>
    </b-tab>
  </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";
import MFdihpias from "./MFdihpias.vue";
import MKu from "./MKu.vue";
import helper from "@/api/helper";


export default {
  components: {
    BTabs,
    BTab,
    BCard,
    MFdihpias,
    MKu,
  },
  data() {
    return {
      text : ""
    };
  },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    
  },
};
</script>
