<template>
    <div>
        <vue-good-table
            class="monitor"
            :columns="columns"
            :rows="rows"
            @on-row-click="onRowClick"
            :select-options="{ enabled: true }"
            @on-selected-rows-change="selectionChanged"
            :search-options="{
                enabled: true
            }"
            :pagination-options="{enabled: true}"
        >
        <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->
              <div v-if="props.column.renderFlag ==false" style="display:block;text-align:center;white-space:nowrap;">
                  {{props.row[props.column.field]}}
              </div>
              <div v-if="props.column.renderFlag ==true" class="icons-visual"> 
                  <a-icon type="check" v-if="props.row[props.column.field] == '1'" style="color:#00FF00"/>
                  <a-icon type="close" v-if="props.row[props.column.field] == '0'" style="color:#FF0000"/>
                  <a-icon type="stop" v-if="props.row[props.column.field] === '-'" />
              </div>
        </template>
        
        </vue-good-table>
        <div style="padding-top:10px;padding-bottom:10px;text-align:right;">   
            <a-button class="btn-success" icon="download" @click="onExportExcel">
                Export Excel
            </a-button>
        </div>
    </div>
</template>

<script>

import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BListGroup,
  BListGroupItem,
  BCard,
  BCardHeader,
  BCardTitle,
  BTabs,
  BTab,
  BFormDatepicker,
  BAvatar,
  BBadge,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { saveExcel } from '@progress/kendo-vue-excel-export';

export default {
    name: 'DinamicTable',
    props: {
      DATAS: Object,
      ExportExcelName : String
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormGroup,
        VueGoodTable,
        saveExcel,
    },
    methods: {
        callParent() {
            this.$emit('onAction', {type : "edit"});
        },
        onRowClick(params){
            this.$emit('onRowClick', params);
        },
        selectionChanged(params){
            this.$emit('selectionChanged', params);
        },
        refreshDataChild(message){
            this.columns = message.columns;
            this.rows    = message.DATAS;
        },
        generateTable: function generateTable (message) {
            this.columns = message.columns;
            this.rows    = message.DATAS;
		},
        onExportExcel(){
            var columnCustome = [];
            for(var i in this.columns ){
                var infoCol = this.columns[i]
                columnCustome.push({
                    field: infoCol.field, 
                    title: infoCol.label
                })
            }
            saveExcel({
                data: this.rows ,
                fileName: this.export_name,
                columns: columnCustome
            });
        },
    },
    data: function () {
        return {
            columns         : [],
            rows            : [],
            searchTerm      : "",
            export_name     : this.ExportExcelName
        };
    },
    watch :{
        columns(val){
        console.log("cols : ",val)
        },
    },

};

</script>

<style>
.icons-visual {
    font-size: 15px !important;
    display: block;
    text-align: center;
    font-weight: bold;
    margin: 5px -15px 8px 0px;
}

.monitor table.vgt-table td {
    padding:10px !important;
    vertical-align: inherit !important;
}

.monitor .vgt-left-align {    
    padding:10px !important;
    vertical-align: inherit !important;
}

.monitor table.vgt-table {
    font-size: 12px !important;
}
</style>