<template>
    <div>
      <div class="row">
        <div class="col-xl-12">
          <b-card>
            <b-row>
              <b-col sm="4" xl="4" md="2">
                <label>Resource *</label>
                <v-select v-model="RESOURCE" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL"
                  :options="RESOURCE_STORE" class="select-size-sm" />
              </b-col>
              <b-col sm="6" xl="6" md="2">
                <label>Stasiun *</label>
                <v-select v-model="STASIUN" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL"
                  :options="STASIUN_STORE" class="select-size-sm" />
              </b-col>
              <b-col sm="2" xl="2" md="1">
                <label>Tahun *</label>
                <v-select  v-model="YEARS" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL"
                  :options="YEARS_STORE" class="select-size-sm" />               
              </b-col>
              <!-- <b-col sm="4 mt-2 pad2" xl="1" md="2">
                <b-form-group>
                  <b-form inline id="select-form">
                    <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="md">Filter</b-button>
                  </b-form>
                </b-form-group>
              </b-col> -->
            </b-row>
            <b-row style="padding-top: 5px;">
                <b-col sm="4" xl="4" md="4">
                <label>Periode</label>
                <v-select multiple v-model="PERIODE" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL"
                  :options="PERIODE_STORE" class="select-size-md" />
              </b-col>
              <b-col sm="4" xl="3" md="3">
                <label>ID Sampel</label>
                <b-form-input
                  type="text"
                  v-model="ID_SAMPEL"
                  size="md"
                  class="text-left mbtm"
                ></b-form-input>
              </b-col>
              <b-col sm="4" xl="3" md="3">
                <label>Progress *</label>
                <v-select  v-model="PROGRESS" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL"
                  :options="PROGRESS_STORE" class="select-size-sm" />
              </b-col>
  
              <b-col sm="4 mt-2 " xl="1" md="2">
                <b-form-group>
                  <b-form inline id="select-form">
                    <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="md">Filter</b-button>
                  </b-form>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
      <a-spin :spinning="isLoading">
        <div class="row">
          <div class="col-xl-12">
            <b-card>
              
              <b-row >
                <!-- <KlimatTable :DATAS="RAW_DATA_TABLE" :ExportExcelName="ExportExcelName" 
                  @selectionChanged="selectionChanged" @onRowClick="onRowClick" ref="dinamicTableComponent">
                </KlimatTable> -->
                <div style="width: 100%; overflow: auto">
                 <!-- table -->
                 <vue-good-table class="monitor" :columns="columns" :rows="rows" @on-row-click="onRowClick"  :pagination-options="{ enabled: true }">
                    <template slot="table-row" slot-scope="props">
                        <!-- Column: Name -->
                        <span v-if="props.column.field === 'link'">
                            <span class="btn btn-sm btn-warning" @click="editDataKU(props.row[props.column.field])">
                                
                                <feather-icon icon="Edit2Icon" class="mr-50" />
                                    <span>Link</span>
                            </span>
                        </span>
                        <span v-else-if="props.column.field === 'progress'">
                            {{ props.row[props.column.field] }}%
                        </span>
                        <!-- Column: Common -->
                        <span v-else>
                        {{ props.row[props.column.field] }}
                        </span>
                        <!-- <div v-if="props.column.field == 'progress'">
                        {{ props.row[props.column.field] }}%
                        </div> -->
                       
                    </template>
                    </vue-good-table>
                </div>
              </b-row>
            </b-card>
          </div>
        </div>
      </a-spin>
    </div>
  </template>
  
  <script>
  import vSelect from "vue-select";
  

  import {
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BPopover,
    BModal,
    VBModal,
    BForm,
    AlertPlugin,
  } from "bootstrap-vue";
  
  import metadata from "@/api/metadata";
  import getstationku from "@/api/getstationku";
  import monitoring from "@/api/monitoring";
  import moment from "moment";
  import { TrashIcon, Edit2Icon } from "vue-feather-icons";
import { VueGoodTable } from "vue-good-table";
  export default {
    components: {
        VueGoodTable,
        TrashIcon,
        Edit2Icon,
      vSelect,
      BOverlay,
      BTable,
      BAvatar,
      BBadge,
      BPagination,
      BInputGroupAppend,
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BFormInput,
      BInputGroup,
      BFormSelect,
      BButton,
      BButtonGroup,
      BFormDatepicker,
      BFormCheckboxGroup,
      BFormCheckbox,
      BFormGroup,
      BFormTextarea,
      BPopover,
      BModal,
      VBModal,
      BForm,
      AlertPlugin,
    },
    data() {
      return {
        ExportExcelName: "BMKGSatu Monitoring",
        isLoading: false,
        showTable: true,
        STASIUN : "",
        STASIUN_STORE : [],
        TAHUN : "",
        TAHUN_STORE : [],
        PERIODE : "",
        PERIODE_STORE : [],
        PROGRESS : {KEY : "1", LABEL:"ALL"},
        PROGRESS_STORE : [
            {KEY : "1", LABEL:"ALL"},
            {KEY : "2", LABEL:"Lengkap"},
            {KEY : "3", LABEL:"Tidak Lengkap"},
        ],
        ID_SAMPEL : "",
        YEARS : "",
        YEARS_STORE : [],
        YEARS_SELECTED:"",
        columns: [
          {
            label: "Stasiun Id",
            field: "station_id",
            width: "95px",
            tdClass: "text-center",
          },
          {
            label: "Nama Stasiun",
            field: "station_name",
          },
          {
            label: "Sampel Id",
            field: "sample_id",
          },
          {
            label: "Tahun",
            field: "tahun",
            width: "75px",
            tdClass: "text-center",
          },
          {
            label: "Periode",
            field: "periode",
            width: "80px",
            tdClass: "text-center",
          },
          {
            label: "Progress",
            field: "progress",
            width: "100px",
          },
          // {
          //   label: "Link",
          //   field: "link",
          //   width: "120px",
          //   tdClass: "text-center",
          // },
        ],
        rows: [],
        RESOURCE: "",
        RESOURCE_STORE: [
          ///PIAS atau FORM

          { KEY: "26", LABEL: "Kimia Air Hujan", CODE: "KimiaAirHujan" },
          { KEY: "27", LABEL: "Suspended Particulate Matter", CODE: "SPM" },
          { KEY: "28", LABEL: "SO2 - NO2", CODE: "SO2NO2" },
          { KEY: "29", LABEL: "Aerosol", CODE: "Aerosol" },
          
        ],
        STASIUN_SELECTED : "",
        TAHUN_SELECTED : "",
        PERIODE_SELECTED : "",
        PROGRESS_SELECTED : "1",

        RESOURCE_SELECTED: "",
        RAW_DATA_TABLE: [],
        pageOptions: [5, 10, 20, 50],
        totalRows: 1,
        currentPage: 1,
        filter: null,
        filterOn: [],
        fields: [],
        filter_data: "",
        perPage: 10,

        DATA_FLAG: [
          ],
        ROW_HIDE: [
          ],
      };
    },
    mounted() {
      this.onYearsStart();
    },
    watch: {
      YEARS(VALUES){
        if(VALUES == null || VALUES == ""){
          this.YEARS_SELECTED = "";
        }else{
          this.YEARS_SELECTED = VALUES.KEY;
        }
        
      },
      STASIUN(VALUES){
        console.log("stasiun : ",VALUES);
        if(VALUES == null || VALUES == ""){
          this.STASIUN_SELECTED = "";
        }else{
          this.STASIUN_SELECTED = VALUES.KEY
        }
      },
      PROGRESS(VALUES){
        if(VALUES == null || VALUES == ""){
          this.PROGRESS_SELECTED = ""
        }else{
          this.PROGRESS_SELECTED = VALUES.KEY
        }
        console.log("PROGRESS_SELECTED : ",this.PROGRESS_SELECTED)
      },
      RESOURCE(VALUES) {
        if(VALUES == null || VALUES == ""){
          this.RESOURCE_SELECTED = "";
          this.PERIODE_STORE = [];
          this.PERIODE = "";
          this.STASIUN = "";
          this.STASIUN_SELECTED = "";
          this.STASIUN_STORE = [];
        }else{
          this.RESOURCE_SELECTED = VALUES.KEY;
          let periode = 0;
          this.PERIODE_STORE = [];
          this.PERIODE = "";
          if(VALUES.KEY == "26"){
              periode = 52;
          }else{
              periode = 61
          }
          for(let i=1;i<=periode;i++){
              var data =  { KEY: i, LABEL: i};
              this.PERIODE_STORE.push(data)
          }
          this.getStasiunKu(VALUES.KEY)
        }
        
      },
      
      KOTAKABUPATEN(VALUES) {
        this.KOTAKABUPATEN_SELECTED = VALUES.KEY;
      },
    },
    methods: {
      moment,
      disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf("day");
      },
      onChange(date, dateString) {
        this.DATE_SELECTED = dateString;
      },
      async getStasiunKu(form){
        var is_form = "";
        // { KEY: "26", LABEL: "Kimia Air Hujan", CODE: "KimiaAirHujan" },
        //   { KEY: "27", LABEL: "Suspended Particulate Matter", CODE: "SPM" },
        //   { KEY: "28", LABEL: "SO2 - NO2", CODE: "SO2NO2" },
        //   { KEY: "29", LABEL: "Aerosol", CODE: "Aerosol" },
        // //   is_aerosol,is_kimia_air_hujan,is_so2_no2,is_spm
        if(form == "26"){
            is_form = "is_kimia_air_hujan"
        }else if(form == "27"){
            is_form = "is_spm"
        }else if(form == "28"){
            is_form = "is_so2_no2"
        }else if(form == "29"){
            is_form = "is_aerosol"
        }
        const { data } = await getstationku.getExStasiunKu(is_form)
        
        this.STASIUN = "";
        this.STASIUN_SELECTED = "";
        this.STASIUN_STORE = [];
        for(var i in data){
            const rowsStasiun = {
              KEY: data[i].station_id,
              LABEL: data[i].station_name,
            };
            this.STASIUN_STORE.push(rowsStasiun)
        }
        
      },
      editDataKU(data){
        console.log("path : ", data)
        var url_base  = window.location.origin
        var path = data.split("bmkgsatu")[1]
        var url = url_base+"/klimatologi?lab_ku&type=kah&path_data="+path;
        console.log("url : ", url)
        window.open(url,"_blank")
      },
      async getData(){
        // var LocalPeriode = "";
        var LocalPeriodeArr = []
        if(this.PERIODE == "" || this.PERIODE == null){
          for(var i in this.PERIODE_STORE){
            LocalPeriodeArr.push(this.PERIODE_STORE[i].KEY);
          }
        }else{
          for(var i in this.PERIODE){
            LocalPeriodeArr.push(this.PERIODE[i].KEY);
          }
        }
        this.PERIODE_SELECTED = LocalPeriodeArr.toString();
        // console.log("PERIODE_STORE : ",this.PERIODE_STORE)
        // console.log("PERIODE : ",this.PERIODE)
        console.log("PERIODE_SELECTED : ",this.PERIODE_SELECTED)
        const {data} = await monitoring.getMonitoringKU(this.RESOURCE_SELECTED,this.STASIUN_SELECTED,this.YEARS_SELECTED,
                            this.PERIODE_SELECTED,this.ID_SAMPEL,this.PROGRESS_SELECTED
        );
        console.log("data : ",data)
        var monitoringArr = data;
        var dMonitor = [
                {
                    "station_id":"KMY",
                    "station_name" : "Kemayoran",
                    "sample_id" : "KMY22010101",
                    "tahun" : "2022",
                    "periode" : 1,
                    "progress" : 38.1,
                    "link" : "1/1/21/1/xx_11_1"
                },
                {
                    "station_id":"KMY",
                    "station_name" : "Kemayoran",
                    "sample_id" : "KMY22010103",
                    "tahun" : "2022",
                    "periode" : 3,
                    "progress" : 98.1,
                    "link" : "1/1/21/1/xx_11_1"
                },
                {
                    "station_id":"KMY",
                    "station_name" : "Kemayoran",
                    "sample_id" : "KMY22010102",
                    "tahun" : "2022",
                    "periode" : 2,
                    "progress" : 48.1,
                    "link" : "1/1/21/1/xx_11_1"
                },
                {
                    "station_id":"KMY",
                    "station_name" : "Kemayoran",
                    "sample_id" : "KMY22010104",
                    "tahun" : "2022",
                    "periode" : 4,
                    "progress" : 91.1,
                    "link" : "1/1/21/1/xx_11_1"
                },
            ];
            
        var colsShort = monitoringArr.sort(function (a, b) {
            return Number(a.periode) - Number(b.periode);
        });
        // var colsShort = dMonitor.sort(function (a, b) {
        //     return Number(a.periode) - Number(b.periode);
        // });
        return colsShort;
      },
      onChangeYearStart(values) {
        this.START_YEARS_SELECTED = Number(values);
      },
      onYearsStart() {
        this.YEARS_STORE = [];
        var DateNow = new Date();
        for (
            var i = Number(DateNow.getFullYear());
            i > Number(DateNow.getFullYear() - 30);
            i--
        ) {
            var opt = {KEY:i,LABEL:i}
            this.YEARS_STORE.push(opt);
        }
      },
      async onFetch() {
        this.isLoading = true;
        this.rows = [];
        // console.log("this.getData() : ",this.getData())
        // console.log("this.rows : ",this.rows)
        // console.log("STASIUN : ",this.STASIUN_SELECTED);
        // console.log("periode : ",this.PERIODE);
        // console.log("tahun  : ",this.YEARS_SELECTED);
        // console.log("progress  : ",this.PROGRESS_SELECTED);

        // return
        if (
          this.RESOURCE_SELECTED == "" ||
          this.YEARS_SELECTED == "" ||
          this.STASIUN_SELECTED == "" ||
          this.PROGRESS_SELECTED == ""
        ) {
          this.$swal({
            title: "ERROR",
            text: "Silahkan Lengkapi Filter",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          this.rows = [];
          this.isLoading = false;
          return;
        }
        console.log("call api")
        this.rows = await this.getData();
        console.log("rows : ",this.rows)
        this.isLoading = false;
        // this.getMonitoring();
      },
      async getMonitoring() {
        this.isLoading = true;
        this.showTable = false;
        this.ExportExcelName =
          "BMKGSatu Monitoring_" + this.RESOURCE.CODE + "_" + this.DATE_SELECTED;
        try {
          const { data } = await monitoring.getMonitoring(
            this.RESOURCE_SELECTED,
            this.BALAI_SELECTED,
            this.PROVINSI_SELECTED,
            this.KOTAKABUPATEN_SELECTED,
            this.DATE_SELECTED
          );
          // console.log("datas : ",data)
          var datas = data;
  
          var cols = [];
          var raws = [];
          var colAvaliable = [];
          var ROW_FLAG = this.DATA_FLAG;
          for (var i in datas) {
            var col = datas[i];
            for (var iCol in col) {
              if (col[iCol].key == "progress") {
                col[iCol].index = col.length;
              }
            }
  
            var j = col.sort(function (a, b) {
              return Number(a.index) - Number(b.index);
            });
            var rawSet = {};
            for (var iCS in colsShort) {
              var infoColShort = colsShort[iCS];
              if (this.ROW_HIDE.indexOf(infoColShort.key) != -1) {
                continue;
              }
              if (colAvaliable.indexOf(infoColShort.key) == -1) {
                colAvaliable.push(infoColShort.key);
                var colsSend = {};
                colsSend["label"] = infoColShort.label;
                colsSend["field"] = infoColShort.key;
                colsSend["renderFlag"] =
                  ROW_FLAG.indexOf(infoColShort.key) != -1 ? true : false;
                cols.push(colsSend);
              }
              if (rawSet[infoColShort.key] == undefined) {
                rawSet[infoColShort.key] = "";
              }
              rawSet[infoColShort.key] = infoColShort.value;
            }
            raws.push(rawSet);
          }
          raws.sort((a, b) => {
            let fa = a.station_name.trim();
            let fb = b.station_name.trim();
  
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          var dataBuild = { DATAS: raws, columns: cols };
          console.log("data : ", dataBuild);
  
          this.RAW_DATA_TABLE = dataBuild;
  
          this.showTable = true;
  
          setTimeout(() => {
            this.$refs.dinamicTableComponent.generateTable(this.RAW_DATA_TABLE);
            this.isLoading = false;
          }, 1000);
        } catch (e) {
          this.isLoading = false;
          console.log(e);
        }
      },
      sortDataTable() {
        var data = this.RAW_DATA_TABLE;
        var TempRaw = {};
        for (var i in data) {
          var items = data[i];
        }
      },
      
      
      async listKabupaten(propinsi_id) {
        try {
          const { data } = await metadata.getKabupatenListByPropinsi(propinsi_id);
          this.KOTAKABUPATEN_STORE = []
          var _selected = "";
          for (var i = 0; i < data.length; i++) {
            const rowsKabupaten = {
              KEY: data[i].kabupaten_id,
              LABEL: data[i].kabupaten_name,
            };
            this.KOTAKABUPATEN_STORE.push(rowsKabupaten);
            _selected = data[i].kabupaten_id;
          }
          // this.KOTAKABUPATEN = _selected;
        } catch (e) {
          console.log(e);
        }
      },
      onRowClick(params) {
        // console.log(params);
      },
      selectionChanged(params) {
        console.log(params);
      },
    },
  };
  </script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/chart-apex.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  @import "@core/scss/vue/libs/vue-good-table.scss";
  </style>
  <style>
  .header-box-custome {
    background: #3f9cff;
    padding: 10px;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .vgt-table thead th.theadc {
		background-color: #f7fafc !important;
	}
  
  .monitor table.vgt-table {
    font-size: 12px !important;
  }
  
  [dir="ltr"] .monitor .vgt-table th {
    padding: 5px 25px 5px 5px !important;
  }
  
  [dir] .monitor table.vgt-table td {
    padding: 5px 25px 5px 5px !important;
  }
  </style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

  